var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "basic-container",
        [
          _c(
            "el-table",
            { staticStyle: { width: "100%" }, attrs: { data: _vm.data } },
            [
              _c("el-table-column", {
                attrs: { prop: "standardCode", label: "标准编号" }
              }),
              _c("el-table-column", {
                attrs: { prop: "standardName", label: "标准名称" }
              }),
              _c("el-table-column", {
                attrs: { prop: "parameterCode", label: "参数编号" }
              }),
              _c("el-table-column", {
                attrs: { prop: "parameterName", label: "参数名称" }
              }),
              _c("el-table-column", {
                attrs: { prop: "specificStandards", label: "标准描述" }
              }),
              _c("el-table-column", {
                attrs: { prop: "minValue", label: "最小值" }
              }),
              _c("el-table-column", {
                attrs: { prop: "maxValue", label: "最大值" }
              })
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "block",
              staticStyle: { float: "right", margin: "30px" }
            },
            [
              _c(
                "div",
                { staticStyle: { "text-align": "right", padding: "50px 0" } },
                [
                  _c("el-button", { on: { click: _vm.resetForm } }, [
                    _vm._v(_vm._s(_vm.$t("Cancel")))
                  ])
                ],
                1
              )
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }